export default {
    data() {
        return {
            user: {},
        };
    },
    methods: {
        changePass() {
            let email = this.$store.getters.getEmail;
            if (
                this.user.currentPassword &&
                this.user.newPassword &&
                this.user.confirmNewPassword
            ) {
                if (this.user.newPassword == this.user.confirmNewPassword) {
                    this.$store
                        .dispatch("settings/changePass", {
                            ...this.user,
                            email: email,
                        })
                        .then((res) => {
                            if (res) {
                                this.user = {};
                            }
                        });
                } else {
                    let errMessage = "Confirm password is wrong";
                    this.$toast.error(errMessage);
                }
            }
        },
    },
};